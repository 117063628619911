import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, Label } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { IDStaticContentEnum, StaticContentInterface } from '../../utils/types'
import { getStats, revalidateNextJsStaticPages, setStats } from '../../api'
import { toast } from 'react-toastify'
import { urlBasedOnEnv } from '../../utils/constants'
import { getPercentageToNumber } from '../../utils/common'

export default function SetStates() {
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = useState<StaticContentInterface>({
    id: IDStaticContentEnum.statistics,
    growthPerYearPercent: 0,
    landValues_aud: 0,
    landValues_pound: 0,
    landValues_eur: 0,
    landValues_usd: 0,
    gbpExchangeRates: {
      pound: 1,
      aud: 0,
      euro: 0,
      usd: 0,
    },
    ConvertedFromGBPAmounts: {
      pound: 0,
      aud: 0,
      euro: 0,
      usd: 0,
    },
    totalAmountsConvertedFromGBP: {
      pound: 0,
      aud: 0,
      euro: 0,
      usd: 0,
    },

    maxLandUnitsForSalePercentage: 88,
    

    dependantRoutes: ['/', '/dashboard', '/business', '/landing', '/rocket1', '/home-old1',]
  })


  useEffect(() => {
    const getStateData = async () => {
      // add try catch block here 
      try {
        setLoading(false);
        const res: any = await getStats();
        console.log('res:', res)
        setState(res.data);
        recalculateValues()
        

      } catch (error: any) {
        console.log('error:', error)
        if (error.response) {
          console.log('error:', error.response.data)
          toast.error(error.response.data);

        }
        setLoading(false);
      }

    };


    getStateData();
  }, [])

  const saveData = async () => {
    try {
      setLoading(false);
      let res: any = await setStats(state)
      setState(res.data);
      if (res) {
        toast.success('Data updated successfully');
      }
      //@ts-ignore
      revalidateNextJsStaticPages(`${urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]}/api/revalidate-static-pages?secret=${process.env.REACT_APP_ISR_TOKEN_NEXT}`)
    } catch (error: any) {
      console.log('error:', error)
      if (error.response) {
        console.log('error:', error.response.data)
        toast.error(error.response.data);

      }
      setLoading(false);

    }

  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: Number(value)
    }));
  }
  const onChangeLV = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: Number(value)
    }));

  }
  const onChangeEX = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    //@ts-ignore
    setState(prevState => ({
      ...prevState,
      gbpExchangeRates: {
        ...prevState.gbpExchangeRates,
        [name]: Number(value)
      }
    }));

  }

  const recalculateValues = () => {

    setState(prevState => {
      const aud = Math.round(prevState.landValues_aud / prevState.gbpExchangeRates.aud);
      const euro = Math.round(prevState.landValues_eur / prevState.gbpExchangeRates.euro);
      const usd = Math.round(prevState.landValues_usd / prevState.gbpExchangeRates.usd);
      const pound = prevState.landValues_pound;
      const totalIn_pound = pound + aud + euro + usd;


      const totalIn_aud = Math.round(totalIn_pound * prevState.gbpExchangeRates.aud);
      const totalIn_euro = Math.round(totalIn_pound * prevState.gbpExchangeRates.euro);
      const totalIn_usd = Math.round(totalIn_pound * prevState.gbpExchangeRates.usd);

      return {
        ...prevState,
        ConvertedFromGBPAmounts: {
          ...prevState.ConvertedFromGBPAmounts,
          aud,
          euro,
          usd,
          pound,

        },
        totalAmountsConvertedFromGBP: {
          ...prevState.totalAmountsConvertedFromGBP,
          aud: totalIn_aud,
          euro: totalIn_euro,
          usd: totalIn_usd,
          pound: totalIn_pound,
        },
        

      }
    });
  }

  useEffect(() => {
    recalculateValues()
  }, [
    state.landValues_pound,
    state.landValues_aud,
    state.landValues_eur,
    state.landValues_usd,
    state.gbpExchangeRates.aud,
    state.gbpExchangeRates.euro,
    state.gbpExchangeRates.usd,
    state.maxLandUnitsForSalePercentage
  ])
  const { totalAmountsConvertedFromGBP } = state;
  console.log('totalAmountsConvertedFromGBP:', totalAmountsConvertedFromGBP)


  console.log('state:', state)
  return (
    <div className="">
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <ol
              className="breadcrumb"
            // style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Set Land Value Statistics</li>


            </ol>
          </div>

        </div>
        <div className="col-md-12 ">
          <div className="  ">

            <div className="row mt-4 pb-4 ">
              <div className="col-md-12 common-box-shadow">
                <div className='p-2 pt-4 pb-5' >
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Land value held in system
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>  GBP</Label>
                        <Input
                          style={{ marginBottom: '1rem' }}
                          type="number"
                          value={state.landValues_pound}
                          name="landValues_pound"
                          onChange={onChangeLV}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> AUD</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          value={state.landValues_aud}
                          name="landValues_aud"
                          onChange={onChangeLV}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> EUR</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          value={state.landValues_eur}
                          name="landValues_eur"
                          onChange={onChangeLV}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> USD</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          value={state.landValues_usd}
                          name="landValues_usd"
                          onChange={onChangeLV}
                        />
                      </div>
                    </div>





                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>Current exchange rates</h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP</Label>
                        <div className='ex-input-wrap d-flex align-items-center'>
                          <span className='text-nowrap mr-1'>1 GBP = </span>
                          <Input
                            // style={{ marginBottom: '1rem' }}
                            type="number"
                            readOnly
                            value={state.gbpExchangeRates?.pound}
                            name="pound"
                            onChange={onChangeEX}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>AUD</Label>
                        <div className='ex-input-wrap d-flex align-items-center'>
                          <span className='text-nowrap mr-1'>1 GBP = </span>
                          <Input
                            // style={{ marginBottom: '1rem' }}
                            type="number"
                            value={state.gbpExchangeRates?.aud}
                            name="aud"
                            onChange={onChangeEX}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>EURO</Label>
                        <div className='ex-input-wrap d-flex align-items-center'>
                          <span className='text-nowrap mr-1'>1 GBP = </span>
                          <Input
                            // style={{ marginBottom: '1rem' }}
                            type="number"
                            value={state.gbpExchangeRates?.euro}
                            name="euro"
                            onChange={onChangeEX}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>USD</Label>
                        <div className='ex-input-wrap d-flex align-items-center'>
                          <span className='text-nowrap mr-1'>1 GBP = </span>
                          <Input
                            // style={{ marginBottom: '1rem' }}
                            type="number"
                            value={state.gbpExchangeRates?.usd}
                            name="usd"
                            onChange={onChangeEX}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Converted from currencies to GBP amounts
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          disabled
                          value={Math.round(state.ConvertedFromGBPAmounts.pound)}
                        />

                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> AUD to GBP</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          disabled
                          value={Math.round(state.ConvertedFromGBPAmounts.aud)}
                          name="landValues_aud"
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> EUR to GBP</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          disabled
                          value={Math.round(state.ConvertedFromGBPAmounts.euro)}

                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'> USD to GBP</Label>
                        <Input

                          style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          disabled
                          value={Math.round(state.ConvertedFromGBPAmounts.usd)}


                        />
                      </div>
                    </div>
                  </div>



                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Total Amount in GBP
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP Total</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          value={Math.round(totalAmountsConvertedFromGBP.pound)}
                        />

                      </div>
                    </div>


                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Total Amounts Converted From GBP to AUD - EUR - USD - (It will be shown in landing pages)
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP Total to AUD</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          value={Math.round(totalAmountsConvertedFromGBP.aud)}
                        />

                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP Total to EUR</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          value={Math.round(totalAmountsConvertedFromGBP.euro)}
                        />

                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>GBP Total to USD</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}
                          type="number"
                          readOnly
                          value={Math.round(totalAmountsConvertedFromGBP.usd)}
                        />

                      </div>
                    </div>


                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Max Land Units for Sale Percentage
                        (GBP : {getPercentageToNumber(state.landValues_pound, state.maxLandUnitsForSalePercentage)})
                        (AUD : {getPercentageToNumber(state.landValues_aud, state.maxLandUnitsForSalePercentage)})
                        (EUR : {getPercentageToNumber(state.landValues_eur, state.maxLandUnitsForSalePercentage)})
                        (USD : {getPercentageToNumber(state.landValues_usd, state.maxLandUnitsForSalePercentage)})
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>Against value of land held</Label>
                        <Input
                          // style={{ marginBottom: '1rem' }}

                          type="number"
                          value={state.maxLandUnitsForSalePercentage}
                          name="maxLandUnitsForSalePercentage"
                          onChange={onChange}
                        />

                      </div>
                    </div>



                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h4>
                        Units growth rate
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <Label className='fw-bold'>Yearly</Label>
                        <Input
                          style={{ marginBottom: '1rem' }}
                          type="number"
                          value={state.growthPerYearPercent}
                          name="growthPerYearPercent"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="" style={{ maxWidth: '200px' }}>
                        <QButton fullWidth loading={loading}
                          onClick={saveData}

                        >
                          <b>Update</b>
                        </QButton>
                      </div>
                    </div>
                  </div>










                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div className="App">

      </div>
    </div>
  )
}
