import { Link } from 'react-router-dom'
import './index.scss'

import { SingleQuestionInterface } from '../../utils/types'
import VoteTopicAdd from '../../component/VoteTopicAndEdit/VoteTopicAdd'

export const voteQuestionSingle: SingleQuestionInterface = {
  questionTitle: ' asdfsadf adsfasdf',
  answerKey: 'A',
  answerOptions: {
    A: 'A asdfasdf',
    B: 'B asdasdfas',
    C: 'C asdfasdfas',
    D: 'D asdfasdfasd',
  },
}

export default function AddQuestion() {
  return (
    <>

      <div className="">
        <div className="container">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">ADD QUESTION/TOPIC</li>
          </ol>
        </div>
        <div className="container">
          <div className="row">

            <div className="col-md-12  pt-5 common-box-shadow  mb-4">
              <h1 style={{ textAlign: 'center' }}>Add Questions</h1>
              <VoteTopicAdd />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
