import React, { useEffect, useState } from 'react'
import Header from '../Sidbar'
import Footer from '../Footer'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import { FormGroup, Input, Label } from 'reactstrap'
import moment from 'moment'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import {
  SingleQuestionInterface,
  VoteTopicObjectInteface,
} from '../../utils/types'
import { validateAdminVotetopicData } from '../../utils/utilities'
import { testAxios, voteQuestionSingle } from '../../utils/constants'

export default function VoteTopicEdit() {
  const { voteTopicID } = useParams()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getSingleVotingTopic()
  }, [voteTopicID])
  const getSingleVotingTopic = async () => {
    try {
      // @ts-ignore
      const url = process.env.REACT_APP_SERVER_URI + `/governance/${voteTopicID}`
      const response: any = await testAxios.get(url)
      console.log('response:', response)
      let data: VoteTopicObjectInteface = response.data

      setLoading(false)

      if (response.data) {
        setVoteTopicTitle(data.voteTopicTitle)
        setvoteDescription(data.voteDescription)
        setstartDate(data.startDate)
        setendDate(data.endDate)
        setVoteQuestionsList(data.voteQuestions)
      } else {
      }
      // console.log("response:", response);
      // setVotingTopics(response.data);
    } catch (error: any) {
      setLoading(false)
      console.log('error:', error.response)
      toast.error(error.response.data.message)
    }
  }
  const [msg, setMsg] = useState<string>('')

  const [voteQuestions, setVoteQuestionsList] = useState<
    SingleQuestionInterface[]
  >([
    {
      ...voteQuestionSingle,
    },
  ])
  const [voteTopicTitle, setVoteTopicTitle] = useState<string>('')
  const [voteStatus, setvoteStatus] = useState<string>('InProgress')
  const [voteDescription, setvoteDescription] = useState<string>('')
  const [startDate, setstartDate] = useState<string>(
    moment().format('YYYY-MM-DD'),
  )
  const [endDate, setendDate] = useState<string>(moment().format('YYYY-MM-DD'))

  const addNewQuestion = (index: number) => {
    setVoteQuestionsList((prev) => {
      const newArray = [...prev]
      newArray.splice(index + 1, 0, { ...voteQuestionSingle })
      return [...newArray]
    })
  }

  const deleteQuestion = (index: number): void => {
    setVoteQuestionsList((prev) => {
      const newArray = [...prev]
      if (newArray.length === 1) {
        toast.error('Last item', {
          position: 'bottom-center',
        })
        return [...prev]
      }
      newArray.splice(index, 1)
      return [...newArray]
    })
  }

  const handleFirstLevelValues = (
    index: number,
    key: string,
    value: string,
  ) => {
    setVoteQuestionsList((prev) => {
      const updatedList = [...prev]

      updatedList[index] = {
        ...updatedList[index],
        [key]: value,
      }

      return updatedList
    })
  }
  const handleSecondValues = (index: number, key: string, value: string) => {
    setVoteQuestionsList((prev) => {
      const updatedList = [...prev]

      updatedList[index] = {
        ...updatedList[index],
        answerOptions: {
          ...updatedList[index].answerOptions,
          [key]: value,
        },
      }

      return updatedList
    })
  }

  const handleSubmit = async () => {
    const data: VoteTopicObjectInteface = {
      voteTopicTitle,
      voteStatus,
      voteDescription,
      startDate,
      endDate,
      voteQuestions,
    }
    if (validateAdminVotetopicData(data)) {
      const url = process.env.REACT_APP_SERVER_URI + '/governance/' + voteTopicID

      try {
        setLoading(true)
        const response = await testAxios.put(url, data).then((res) => {
          toast.success(res.data.message, {
            position: 'bottom-center',
          })
          setLoading(false)
          setMsg('')
        })
      } catch (error: any) {
        setLoading(false)
        toast.error(error.response.data.message, { position: 'bottom-center' })
      }
    } else {
      toast.error('Please enter all data', {
        position: 'bottom-center',
      })
    }
  }
  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="form-group">
            <div className="form-group col-md-12">
              <div className="form-group">
                <Label>
                  <b> Enter Voting Title</b>
                </Label>
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Voting Title"
                  value={voteTopicTitle}
                  onChange={(e) => setVoteTopicTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <Label>
                  <b> Enter Vote Description</b>
                </Label>
                <textarea
                  className="form-control"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Vote Description"
                  value={voteDescription}
                  onChange={(e) => setvoteDescription(e.target.value)}
                />
              </div>
              <Label>
                <b> Select Vote Date Range:</b>
              </Label>
              <div className="d-flex">
                <SingleDatePickerComponent
                  setDate={(d) => setstartDate(d.format('YYYY-MM-DD'))}
                  date={startDate ? moment(startDate) : null}
                  id={'startDate'}
                  placeholder="Start date"
                />
                <SingleDatePickerComponent
                  setDate={(d) => setendDate(d.format('YYYY-MM-DD'))}
                  date={endDate ? moment(endDate) : null}
                  id={'endDate'}
                  placeholder="End date"
                />
              </div>
            </div>
            <div className="col-md-128 ">
              <div className="vote-questions-list mt-5">
                <h3>Questions List</h3>
                {voteQuestions.map((q, qi) => {
                  return (
                    <div className="single-question">
                      <h6>Question {qi + 1}</h6>
                      <FormGroup>
                        <Input
                          value={q.questionTitle}
                          onChange={(e) =>
                            handleFirstLevelValues(
                              qi,
                              'questionTitle',
                              e.target.value,
                            )
                          }
                        />
                      </FormGroup>
                      <div className="question-options">
                        <h6>Select the right answer</h6>
                        {['A', 'B', 'C', 'D'].map((key) => (
                          <Label className="input-group" for={`${key + qi}`}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <Label for={`${key + qi}`}>{key} </Label>
                                <input
                                  id={key + qi}
                                  type="radio"
                                  aria-label="Radio button for following text input"
                                  value={key}
                                  checked={key === q.answerKey}
                                  onChange={(e) =>
                                    handleFirstLevelValues(
                                      qi,
                                      'answerKey',
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <input
                              className="form-control"
                              key={key}
                              type="text"
                              placeholder={`Enter the option of ${key}`}
                              value={
                                q.answerOptions[key as 'A' | 'B' | 'C' | 'D']
                              }
                              onChange={(e) =>
                                handleSecondValues(qi, key, e.target.value)
                              }
                            />
                          </Label>
                        ))}
                      </div>
                      <div
                        className="d-flex mt-3 justify-content-end"
                        role="group"
                        aria-label="Basic example"
                      >
                        <QButton
                          round
                          className="btn btn-primary mr-3"
                          onClick={() => deleteQuestion(qi)}
                        >
                          <i className="fa fa-trash mr-2"></i>
                          Delete
                        </QButton>
                        <QButton
                          round
                          className="btn btn-primary"
                          onClick={() => addNewQuestion(qi)}
                        >
                          <i className="fa fa-plus-circle mr-2"></i>
                          Add New Question
                        </QButton>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="text-right ml-auto" style={{ maxWidth: '250px' }}>
                <QButton
                  round
                  loading={loading}
                  onClick={handleSubmit}
                  fullWidth
                >
                  <b>Update</b>
                </QButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
