import { Key, useEffect, useState } from 'react'
import { getUserOrderApprovedOrCompleted } from '../../api'
import Header from '../../component/Sidbar'
import { Link } from 'react-router-dom'
//@ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table'
import {
  SelectComponentValue,
  SortableTableHeader,
  TableTopFilters,
  TableTopFiltersNewUI,
  TRInfo,
} from '../../component/misc/smallComponents'
import Footer from '../../component/Footer'
import {
  CurrencyDisplaySymbol,
  ONE_USD_TO_CENTS,
  OrderStatus,
  PaymentMethodEnum,
  Transaction,
  UnitDisplayNames,
  UnitDisplaySymbol,
  UnitType,
} from '../../utils/types'
import {
  formatDate,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
  getEnteredByName,
  ONE_AUD_TO_CENTS,
} from '../../utils/utilities'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../component/misc/SvgIcons'
import { camelCaseToSpaced } from '../../utils/common'

const ApproveCompleted = () => {
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    orderStatus: '',
    orderType: '',
    unitType: '',
    paymentMethod: '',
    userType: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })

  const getData = async () => {
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
      } = state
      const res = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
      )
      setState((prevState: any) => ({
        ...prevState,
        dataList: res.data,
        filteredData: res.data,
      }))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    // state.searchTerm,
    state.searchType,
    state.fromDate,
    state.toDate,
    state.paymentMethod,
    state.userType,
    state.unitType,
    state.orderType,
  ])

  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.orderStatus,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.orderStatus) {
      filtered = filtered.filter(
        (order: any) => order.orderStatus === state.orderStatus,
      )
    }

    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      orderStatus: '',
      orderType: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      // const newSortDirection =
      //   property === prevState.sortBy
      //     ? prevState.sortDirection === 'asc'
      //       ? 'desc'
      //       : 'asc'
      //     : prevState.sortDirection
      return {
        ...prevState,
        sortBy: property,
        // sortDirection: newSortDirection
      }
    })
  }
  const handleExportOrder = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        paymentMethod,
        unitType,
        userType,
        orderType,
      } = state
      const response = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
        true,
      )
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  const { sortBy, sortDirection } = state
  return (
    <>
      <div className="card-ui-pages-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  USER PURCHASES/SALES (APPROVED/COMPLETED)
                </li>
              </ol>
              <div className="">
                <div className="">
                  <TableTopFiltersNewUI
                    state={state}
                    setState={setState}
                    getData={getData}
                    handleExportOrders={handleExportOrder}
                    sortInputs={
                      <>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sort Order</Label> */}
                          <SelectComponentValue
                            value={sortDirection}
                            placeholder="Sort Order"
                            onChange={(sortDirection) =>
                              // setFilterPaymentMethod(value)
                              // @ts-ignore
                              setState((prev) => ({ ...prev, sortDirection }))
                            }
                            options={[
                              { value: 'asc', label: 'Ascending' },
                              { value: 'desc', label: 'Descending' },
                            ]}
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sory By</Label> */}
                          <SelectComponentValue
                            value={sortBy}
                            placeholder="Sory By"
                            onChange={(sortBy) => {
                              // @ts-ignore
                              handleSortTableHead(sortBy)
                            }}
                            options={[
                              { label: 'Number of Units', value: 'units' },
                              { label: 'Value', value: 'amount' },
                            ]}
                          />
                        </div>
                      </>
                    }
                  />

                  <div className="tr-card-list">
                    {state?.filteredData &&
                      state.filteredData.map(
                        (item: Transaction, index: number) => (
                          <div className="tr-card">
                            <div className="info-icon-wrap">
                              <div className="ic-label-wrap">
                                <div className="ic-label withbg">
                                  {SvgIcons.MoneyCash}
                                  <span>
                                    {camelCaseToSpaced(item.orderStatus)}
                                  </span>
                                </div>
                              </div>
                              <div className="ic-label-wrap">
                                <div className="ic-label withbg">
                                  {SvgIcons.CheckCircle}
                                  <span>
                                    {
                                      UnitDisplayNames[
                                        item.currencyUnit?.unitType as UnitType
                                      ]
                                    }{' '}
                                    (
                                    {formatNumberWithCommas(
                                      item.currencyUnit.numberOfUnits,
                                    )}
                                    ) -{' '}
                                    {/* <i className="fa fa-euro mr-6"></i>{' '} */}
                                    {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                      item.paymentDetails?.paymentAmount /
                                        ONE_USD_TO_CENTS,
                                    )}{' '}
                                    {
                                      CurrencyDisplaySymbol[
                                        item.paymentDetails.currency
                                      ]
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="ic-label-wrap">
                                <div
                                  className={
                                    item.orderType !== 'BUY'
                                      ? 'ic-label up'
                                      : 'ic-label'
                                  }
                                >
                                  {SvgIcons.ArrowDown}
                                  <span>{item.orderType}</span>
                                </div>
                              </div>
                            </div>
                            <div className="info-text">
                              <div className="info-text-col-wrap">
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        item.user?.firstName +
                                        ' ' +
                                        item.user?.lastName
                                      }
                                      label={'Name'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        item &&
                                        new Date(
                                          item.createdAt,
                                        ).toLocaleDateString()
                                      }
                                      label={'Created Date'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={item.user?.email}
                                      label={'Email'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={getEnteredByName(item.approvedBy)}
                                      label={'Approved by'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        item.paymentDetails?.paymentMethod
                                          ? item.paymentDetails?.paymentMethod
                                          : '--'
                                      }
                                      label={'Payment Method'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        item.buy?.paymentId
                                          ? item.buy.paymentId
                                          : '--'
                                      }
                                      label={'Payment Reference'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApproveCompleted
