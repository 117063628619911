import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AdminRoles } from '../../utils/types'
import { getAllUserAdminQuery, putAssignOrRemoveAdminRole } from '../../api'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'

export default function AssignOrRemoveAdminRole() {
  const [users, setUsers] = useState<any>('')
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')

  const [loading, setLoading] = useState<boolean>(false) // Add loading state
  const [editableRoles, setEditableRoles] = useState<Record<string, string[]>>(
    {},
  )
  const adminRoles = Object.values(AdminRoles)

  const handleCheckboxChange = (userId: string, role: string) => {
    setEditableRoles((prevRoles) => {
      const userRoles = [...(prevRoles[userId] || [])]
      const roleIndex = userRoles.indexOf(role)
      if (roleIndex !== -1) {
        userRoles.splice(roleIndex, 1)
      } else {
        userRoles.push(role)
      }
      return { ...prevRoles, [userId]: userRoles }
    })
  }

  const handleSave = (userId: string) => {
    const roleAndUserId = {
      userId,
      roles: editableRoles[userId],
    }
    putAssignOrRemoveAdminRole(roleAndUserId)
      .then((res) => {
        toast.success(res.data.message)
        // Update only the roles of the user whose roles were modified
        setUsers((prevData: any[]) => {
          return prevData.map((item: any) => {
            if (item._id === userId) {
              return {
                ...item,
                roles: res.data.users.find((user: any) => user._id === userId)
                  ?.roles,
              }
            }
            return item
          })
        })
      })
      .catch((error: any) => {
        console.log('error', error)
        toast.error(error.response.data.message)
      })
  }

  const getAllAdminAndUser = async () => {
    setLoading(true) // Set loading state to true before making API request

    getAllUserAdminQuery(searchType, searchTerm)
      .then((res) => {
        const adminUsers = res.data.users
        setUsers(adminUsers)
        const initialSelectedRoles: Record<string, string[]> = {}
        adminUsers.forEach(
          (user: { _id: string | number; roles: string[] }) => {
            initialSelectedRoles[user._id] = user.roles
          },
        )
        setEditableRoles(initialSelectedRoles)
      })
      .catch((err) => {
        console.log('error=============', err)
        toast.error(err.response.data.message)
      })
      .finally(() => {
        setLoading(false) // Set loading state to false after API request completes
      })
  }

  useEffect(() => {
    getAllAdminAndUser()
  }, [])
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Assign/remove Admin role</li>
          </ol>
          <div className="card mb-3">
            <div className="card-body">
              <div className="table-responsive">
                <div>
                  <h3>Admin/User List</h3>
                  {/* <select className="mr-sm-1 mb-sm-2" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
										<option value="name">Search user/admin by Name</option>
										<option value="email">Search user/admin by Email</option>
									</select>
									<input type="text" value={query} onChange={(e) => setQuery(e.target.value)} />  */}

                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search user/admin by Name</option>
                      <option value="email">Search user/admin by Email</option>
                    </select>

                    <SearchInput
                      onClick={getAllAdminAndUser}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                      // loading={userLoading}
                    />
                  </FormGroup>
                </div>
                <table
                  className="table table-bordered text-nowrap text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0 "
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>KYC Status</th>
                      {adminRoles
                        .filter(
                          (filterRole) => filterRole !== AdminRoles.SuperAdmin,
                        )
                        .map((role) => (
                          <th key={role}>{role}</th>
                        ))}
                      <th>Action</th>
                    </tr>
                  </thead>

                  {loading ? (
                    <h2 className="text-center">Loading....</h2>
                  ) : (
                    <>
                      {users &&
                        users.map((item: any, index: number) => {
                          return (
                            <tr key={index} className="text-center">
                              <td>
                                <b>{index + 1}.</b>
                              </td>
                              <td>{item?.firstName + ' ' + item?.lastName}</td>
                              <td>{item?.email}</td>
                              <td>{item?.kycInfo.kycStatus}</td>
                              {adminRoles
                                .filter(
                                  (filterRole) =>
                                    filterRole !== AdminRoles.SuperAdmin,
                                )
                                .map((role) => (
                                  <td key={`${item._id}-${role}`}>
                                    <input
                                      type="checkbox"
                                      checked={editableRoles[
                                        item._id
                                      ]?.includes(role)}
                                      onChange={() =>
                                        handleCheckboxChange(item._id, role)
                                      }
                                    />
                                  </td>
                                ))}
                              <td>
                                <div className="">
                                  <button
                                    className="btn_1 rounded  color btnCenter"
                                    onClick={() => handleSave(item._id)}
                                  >
                                    Save
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}
