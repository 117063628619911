import React from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
  return (
    <>
      <div className="primary-admin-footer">
        <div className="container">
          <div className="align-items-center d-flex flex-column flex-sm-row justify-content-between">
            <Link to="/">
              <span className="cname mb-0">
                QUANTUM<i className="two">2</i>
              </span>
            </Link>
            <span className="mb-0 text-uppercase">
              © 2023, QUANTUM<i className="two">2</i>{' '}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
